import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const AdminAddEditExamQuiz = ({ edit, isExam }) => {
  const [values, setValues] = useState(null);
  const { sessionID } = useParams();
  const [date, setDate] = useState([]);
  const [typeExam, setTypeExam] = useState(0);
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditExam": {
        return {
          ...state,
          submitAddEditExam: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddEditExam: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const handleChangeDate = (dates) => {
    if (dates[0] && dates[1]) {
      const startDate = dates[0];
      const endDate = dates[1];
      setDate([startDate, endDate]);
    }
  };

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_TRIAL_EXAM_API
    : process.env.REACT_APP_ADMIN_ADD_COURSE_EXAM_API;

  //!--------- add edit exam -------
  const [ExamAddEditSuccess, ExamAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditExam.flag,
    state.submitAddEditExam.dependency,
    state.submitAddEditExam.data,
    true
  );

  //!--------- get the exam info for editing -------

  const [ExamInfo, ExamInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_INFO_API}/${sessionID}`,
    "GET",
    sessionID,
    sessionID
  );

  useEffect(() => {
    if (ExamInfo) {
      let temp = ExamInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        is_free: temp.is_free === 1 ? true : false,
        nextlecture: temp.nextlecture === null ? 0 : temp.nextlecture,
      });
      setDate([dayjs(temp?.date_from), dayjs(temp?.date_to)]);
      setTypeExam(temp?.is_duration === 0 ? 1 : 0);
    }
  }, [ExamInfo]);

  useEffect(() => {
    if (ExamAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ExamAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const [from, to] = date;
    const finalData = edit
      ? {
          ...data,
          id: sessionID,
          visibility: +data?.visibility,
          v_model: +data?.visibility,
          is_free: +data?.is_free,
          date_from: from ? dayjs(from).format("YYYY-MM-DD HH:mm:ss") : null,
          date_to: to ? dayjs(to).format("YYYY-MM-DD HH:mm:ss") : null,
          is_duration: +typeExam === 0 ? 1 : 0,
        }
      : {
          ...data,
          session_id: sessionID,
          is_exam: isExam ? 1 : 0,
          v_model: +data?.visibility,
          is_free: +data?.is_free,
          date_from: from ? from : null,
          date_to: to ? to : null,
          is_duration: +typeExam === 0 ? 1 : 0,
        };
    dispatch({
      type: "setSubmitAddEditExam",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditExam.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Exams | Nash Academy "></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {edit
            ? "Edit Exam"
            : "Please fill out the information to add the test"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit  w-3/4 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl bg-blue-200 p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
        >
          {/** Name price filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            {/* Type */}
            <div className="w-full md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="type">Duration or Deadline</label>
              <select
                value={typeExam}
                onChange={(e) => setTypeExam(e.target.value)}
                className="signin-inputs w-full pl-4 "
              >
                <option value={0}>duration</option>
                <option value={1}>deadline</option>
              </select>
            </div>

            {+typeExam === 0 ? (
              /* duration */
              <div className="w-full md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
                <label htmlFor="duration">Duration</label>
                <input
                  className="signin-inputs w-full pl-4    "
                  type="text"
                  id="duration"
                  placeholder="Duration"
                  name="duration"
                  autoComplete="on"
                  {...register("duration", {
                    required: false,
                    minLength: 1,
                  })}
                />
                {errors.duration && (
                  <p className="w-full text-end  text-xl text-blue-900  ">
                    {errors.duration.type === "required" &&
                      "Please fill out this field"}
                    {errors.duration.type === "minLength" &&
                      "Please type at least 1 character"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.duration && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.duration[0]}
                      </p>
                    )
                }
              </div>
            ) : (
              /* Deadline */
              <div className="w-full md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
                <label htmlFor="deadline">Deadline</label>
                <RangePicker
                  className=""
                  showTime
                  defaultValue={date}
                  onChange={(_, dateString) => handleChangeDate(dateString)}
                />

                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.deadline && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.deadline[0]}
                      </p>
                    )
                }
              </div>
            )}
          </div>
          {/** visibility homework quiz fields */}
          {edit && (
            <div className="flex  w-full items-  gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-1/3  md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="v_model"
                      name="v_model"
                      {...register("v_model", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="v_model"
                    >
                      Show Answer{" "}
                    </label>
                  </div>
                </div>

                {errors.v_model && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.v_model.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.v_model && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.v_model[0]}
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/3  md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility{" "}
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.visibility.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.visibility && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {
                          ExamAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/3  md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="is_free"
                      name="is_free"
                      {...register("is_free", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="is_free"
                    >
                      Free{" "}
                    </label>
                  </div>
                </div>

                {errors.is_free && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.is_free.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.is_free && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.is_free[0]}
                      </p>
                    )
                }
              </div>
            </div>
          )}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="youtube_video_url">
              Youtube Video Url{" "}
            </label>
            <textarea
              className=" resize-none"
              {...register("youtube_video_url", {
                minLength: 3,
              })}
              name="youtube_video_url"
              id="youtube_video_url"
              placeholder="Youtube Video Url"
              cols="40"
              rows="5"
            ></textarea>
            {errors.youtube_video_url && (
              <p className=" text-xl text-blue-900 ">
                {errors.youtube_video_url.type === "minLength" &&
                  "Please write at least 3 letters"}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors
                  ?.youtube_video_url && (
                  <p className="w-full text-end  text-xl text-blue-900  ">
                    {
                      ExamAddEditErrors?.response?.data?.errors
                        ?.youtube_video_url[0]
                    }
                  </p>
                )
            }
          </div>

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description{" "}
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className=" text-xl text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 letters"}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full text-end  text-xl text-blue-900  ">
                    {ExamAddEditErrors?.response?.data?.errors?.description[0]}
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditExam.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditExam.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{edit ? "Confirm Edit" : "Add"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminAddEditExamQuiz;
