/* eslint-disable import/first */
import { Suspense, lazy } from "react";
const LazyLandingPage = lazy(() => import("./Pages/LandingPage/LandingPage"));
// eslint-disable-next-line import/first
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./SASS/signIn.scss";
import "./SASS/styles.scss";

import { Layout, Loader, NotFound } from "./MainComponents";
import {
  SignIn,
  Register,
  StudentLectures,
  StudentLecture,
  Exams,
  SingleExamPage,
  StudentQuestionBank,
  QuizNew,
  StudentShowQuestionBank,
  Profile,
  Amount,
  Invitations,
  StudentSession,
  StudentMaterials,
  StudentMaterialsView,
  StudentMaterialsViewReadingOrWriting,
  StudentMaterialsPracticesView,
  StudentMaterialsPdfsView,
  StudentMaterialsPracticesQuizzesView,
  HomePage,
} from "./Pages/Student";
import {
  AdminLayout,
  Students,
  EditStudent,
  AdminExamsQuizzes,
  Requests,
  Assistants,
  AddEditAssistant,
  ViewStudent,
  AdminProfile,
  AdminAddEditExamAndQuizzes,
  AdminViewExam,
  AdminQuiz,
  AdminQuizSection,
  AdminCourses,
  AdminAddEditCourse,
  AdminCourseDetails,
  AdminAddEditSession,
  AdminAddStudentToCourse,
  AdminCourseSession,
  AdminAddEditMatrialSession,
  AdminMaterials,
  AdminAddEditMaterial,
  AdminMatrialPractises,
  AdminAddEditMatrialExamAndQuiz,
  AdminViewMatrials,
  AdminMatrialsType,
  AdminMaterialsPdf,
  AdminMatrialPractisesView,
  AdminMatrialPractisesQuizzesView,
  Posts,
  AddEditPost,
  AdminAddEditExamQuiz,
} from "./Pages/Admin";

import ProtectedAdminRoutes from "./MainComponents/ProtectionComponents/ProtectedAdminRoutes";
import { useAuthState } from "./MainComponents/GlobalContext.jsx";
import ModalAnswer from "./Pages/Student/Lectures/modalAnswer/ModalAnswer";
import AdminModalAnswer from "./Pages/Admin/Dashboard/ExamsQuizzes/AdminModalAnswer.jsx";
import ForgetPassword from "./Pages/Student/SignIn/ForgetPassword.jsx";
import LandingLayout from "./MainComponents/LandingLayout.jsx";
import StudentMaterialsStarter from "./Pages/Student/StudentMaterials/StudentMaterialsStarter.jsx";
import AdminMaterialsStarter from "./Pages/Admin/Dashboard/AdminMaterials/AdminMatrialsStarter.jsx";
import AddEditChooseQuestion from "./Pages/Admin/Dashboard/QuestionBank/AddEditChooseQuestion.jsx";

function App() {
  const AuthState = useAuthState();

  const router = createBrowserRouter([
    //!--------Landing Page Layout--------

    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/home/courses"
          isAllowed={!AuthState.userData?.student_token}
        >
          <LandingLayout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Loader />}>
              <LazyLandingPage />
            </Suspense>
          ),
        },
        {
          path: "/homeschooling",
          element: <HomePage landing />,
        },
        // courses
        {
          path: "/pre-courses",
          element: <StudentLectures landing />,
        },
        {
          path: "/pre-courses/sessions/:courseID",
          element: <StudentSession landing />,
        },
        {
          path: "/pre-courses/sessions/:courseID/:sessionID",
          element: <StudentLecture landing />,
        },

        // est matrials
        {
          path: "/est-materials",
          element: <StudentMaterialsStarter section="est" landing />,
        },
        {
          path: "/est-materials/matrials",
          element: <StudentMaterialsView section="est" landing />,
        },
        {
          path: "/est-materials/past-paper",
          element: (
            <StudentMaterials
              section="est"
              pdfs
              pdfsType="past-paper"
              landing
            />
          ),
        },
        {
          path: "/est-materials/matrials/reading",
          element: (
            <StudentMaterialsViewReadingOrWriting section="est" landing />
          ),
        },
        {
          path: "/est-materials/matrials/writing",
          element: (
            <StudentMaterialsViewReadingOrWriting section="est" landing />
          ),
        },
        {
          path: "/est-materials/matrials/reading/videos",
          element: <StudentMaterials section="est" reading videos landing />,
        },
        {
          path: "/est-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="est" landing />,
        },
        {
          path: "/est-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials
              section="est"
              reading
              pdfs
              pdfsType="tests"
              landing
            />
          ),
        },
        {
          path: "/est-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials
              section="est"
              reading
              pdfs
              pdfsType="books"
              landing
            />
          ),
        },
        {
          path: "/est-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials
              section="est"
              reading
              pdfs
              pdfsType="topics"
              landing
            />
          ),
        },

        {
          path: "/est-materials/matrials/writing/videos",
          element: <StudentMaterials section="est" videos landing />,
        },
        {
          path: "/est-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="est" landing />,
        },
        {
          path: "/est-materials/matrials/writing/pdfs/tests",
          element: (
            <StudentMaterials section="est" pdfs pdfsType="tests" landing />
          ),
        },
        {
          path: "/est-materials/matrials/writing/pdfs/books",
          element: (
            <StudentMaterials section="est" pdfs pdfsType="books" landing />
          ),
        },
        {
          path: "/est-materials/matrials/writing/pdfs/topics",
          element: (
            <StudentMaterials section="est" pdfs pdfsType="topics" landing />
          ),
        },

        {
          path: "/est-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="est" landing />,
        },
        {
          path: "/est-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="est" landing />,
        },
        {
          path: "/est-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials
              section="est"
              reading
              practicesType="exams"
              landing
            />
          ),
        },
        {
          path: "/est-materials/matrials/writing/practices/exams",
          element: (
            <StudentMaterials section="est" practicesType="exams" landing />
          ),
        },
        {
          path: "/est-materials/matrials/reading/practices/quizzes",
          element: (
            <StudentMaterialsPracticesQuizzesView section="est" landing />
          ),
        },
        {
          path: "/est-materials/matrials/writing/practices/quizzes",
          element: (
            <StudentMaterialsPracticesQuizzesView section="est" landing />
          ),
        },
        {
          path: "/est-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials
              section="est"
              reading
              practicesType="topics"
              landing
            />
          ),
        },
        {
          path: "/est-materials/matrials/writing/practices/quizzes/topics",
          element: (
            <StudentMaterials section="est" practicesType="topics" landing />
          ),
        },
        {
          path: "/est-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials
              section="est"
              reading
              practicesType="drills"
              landing
            />
          ),
        },
        {
          path: "/est-materials/matrials/writing/practices/quizzes/drills",
          element: (
            <StudentMaterials section="est" practicesType="drills" landing />
          ),
        },

        // act matrials
        {
          path: "/act-materials",
          element: <StudentMaterialsStarter section="act" landing />,
        },
        {
          path: "/act-materials/matrials",
          element: <StudentMaterialsView section="act" landing />,
        },
        {
          path: "/act-materials/past-paper",
          element: (
            <StudentMaterials
              section="act"
              pdfs
              pdfsType="past-paper"
              landing
            />
          ),
        },
        {
          path: "/act-materials/matrials/reading",
          element: (
            <StudentMaterialsViewReadingOrWriting section="act" landing />
          ),
        },
        {
          path: "/act-materials/matrials/writing",
          element: (
            <StudentMaterialsViewReadingOrWriting section="act" landing />
          ),
        },
        {
          path: "/act-materials/matrials/reading/videos",
          element: <StudentMaterials section="act" reading videos landing />,
        },
        {
          path: "/act-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="act" landing />,
        },
        {
          path: "/act-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials
              section="act"
              reading
              pdfs
              pdfsType="tests"
              landing
            />
          ),
        },
        {
          path: "/act-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials
              section="act"
              reading
              pdfs
              pdfsType="books"
              landing
            />
          ),
        },
        {
          path: "/act-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials
              section="act"
              reading
              pdfs
              pdfsType="topics"
              landing
            />
          ),
        },

        {
          path: "/act-materials/matrials/writing/videos",
          element: <StudentMaterials section="act" videos landing />,
        },
        {
          path: "/act-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="act" landing />,
        },
        {
          path: "/act-materials/matrials/writing/pdfs/tests",
          element: (
            <StudentMaterials section="act" pdfs pdfsType="tests" landing />
          ),
        },
        {
          path: "/act-materials/matrials/writing/pdfs/books",
          element: (
            <StudentMaterials section="act" pdfs pdfsType="books" landing />
          ),
        },
        {
          path: "/act-materials/matrials/writing/pdfs/topics",
          element: (
            <StudentMaterials section="act" pdfs pdfsType="topics" landing />
          ),
        },

        {
          path: "/act-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="act" landing />,
        },
        {
          path: "/act-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="act" landing />,
        },
        {
          path: "/act-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials
              section="act"
              reading
              practicesType="exams"
              landing
            />
          ),
        },
        {
          path: "/act-materials/matrials/writing/practices/exams",
          element: (
            <StudentMaterials section="act" practicesType="exams" landing />
          ),
        },
        {
          path: "/act-materials/matrials/reading/practices/quizzes",
          element: (
            <StudentMaterialsPracticesQuizzesView section="act" landing />
          ),
        },
        {
          path: "/act-materials/matrials/writing/practices/quizzes",
          element: (
            <StudentMaterialsPracticesQuizzesView section="act" landing />
          ),
        },
        {
          path: "/act-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials
              section="act"
              reading
              practicesType="topics"
              landing
            />
          ),
        },
        {
          path: "/act-materials/matrials/writing/practices/quizzes/topics",
          element: (
            <StudentMaterials section="act" practicesType="topics" landing />
          ),
        },
        {
          path: "/act-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials
              section="act"
              reading
              practicesType="drills"
              landing
            />
          ),
        },
        {
          path: "/act-materials/matrials/writing/practices/quizzes/drills",
          element: (
            <StudentMaterials section="act" practicesType="drills" landing />
          ),
        },

        // dsat matrials
        {
          path: "/dsat-materials",
          element: <StudentMaterialsStarter section="dsat" landing />,
        },
        {
          path: "/dsat-materials/matrials",
          element: <StudentMaterialsView section="dsat" landing />,
        },
        {
          path: "/dsat-materials/past-paper",
          element: (
            <StudentMaterials
              section="dsat"
              pdfs
              pdfsType="past-paper"
              landing
            />
          ),
        },
        {
          path: "/dsat-materials/matrials/reading",
          element: (
            <StudentMaterialsViewReadingOrWriting section="dsat" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/writing",
          element: (
            <StudentMaterialsViewReadingOrWriting section="dsat" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/reading/videos",
          element: <StudentMaterials section="dsat" reading videos landing />,
        },
        {
          path: "/dsat-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="dsat" landing />,
        },
        {
          path: "/dsat-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials
              section="dsat"
              reading
              pdfs
              pdfsType="tests"
              landing
            />
          ),
        },
        {
          path: "/dsat-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials
              section="dsat"
              reading
              pdfs
              pdfsType="books"
              landing
            />
          ),
        },
        {
          path: "/dsat-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials
              section="dsat"
              reading
              pdfs
              pdfsType="topics"
              landing
            />
          ),
        },

        {
          path: "/dsat-materials/matrials/writing/videos",
          element: <StudentMaterials section="dsat" videos landing />,
        },
        {
          path: "/dsat-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="dsat" landing />,
        },
        {
          path: "/dsat-materials/matrials/writing/pdfs/tests",
          element: (
            <StudentMaterials section="dsat" pdfs pdfsType="tests" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/writing/pdfs/books",
          element: (
            <StudentMaterials section="dsat" pdfs pdfsType="books" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/writing/pdfs/topics",
          element: (
            <StudentMaterials section="dsat" pdfs pdfsType="topics" landing />
          ),
        },

        {
          path: "/dsat-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="dsat" landing />,
        },
        {
          path: "/dsat-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="dsat" landing />,
        },
        {
          path: "/dsat-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials
              section="dsat"
              reading
              practicesType="exams"
              landing
            />
          ),
        },
        {
          path: "/dsat-materials/matrials/writing/practices/exams",
          element: (
            <StudentMaterials section="dsat" practicesType="exams" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/reading/practices/quizzes",
          element: (
            <StudentMaterialsPracticesQuizzesView section="dsat" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/writing/practices/quizzes",
          element: (
            <StudentMaterialsPracticesQuizzesView section="dsat" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials
              section="dsat"
              reading
              practicesType="topics"
              landing
            />
          ),
        },
        {
          path: "/dsat-materials/matrials/writing/practices/quizzes/topics",
          element: (
            <StudentMaterials section="dsat" practicesType="topics" landing />
          ),
        },
        {
          path: "/dsat-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials
              section="dsat"
              reading
              practicesType="drills"
              landing
            />
          ),
        },
        {
          path: "/dsat-materials/matrials/writing/practices/quizzes/drills",
          element: (
            <StudentMaterials section="dsat" practicesType="drills" landing />
          ),
        },
      ],
    },
    //!Student Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/signin"
          isAllowed={AuthState.userData?.student_token}
        >
          <Layout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/home/profile",
          element: <Profile />,
        },
        {
          path: "/home/amount",
          element: <Amount />,
        },
        {
          path: "/home/invitations",
          element: <Invitations />,
        },
        {
          path: "/home/courses",
          element: <StudentLectures />,
        },
        {
          path: "/home/courses/sessions/:courseID",
          element: <StudentSession />,
        },
        {
          path: "/home/courses/sessions/:courseID/:sessionID",
          element: <StudentLecture />,
        },
        {
          path: "/home/courses/sessions/modalAnswer/:lectureID",
          element: <ModalAnswer />,
        },
        {
          path: "/home/courses/sessions/solve-exam/:ID",
          element: <SingleExamPage />,
        },
        {
          path: "home/exams",
          element: <Exams />,
        },
        {
          path: "/home/exams/:examID",
          element: <SingleExamPage />,
        },
        {
          path: "/home/exams/solve-test/:ID",
          element: <QuizNew exam />,
        },
        {
          path: "/home/exams/modal-answer/:ID",
          element: <ModalAnswer exam />,
        },
        //!Quiz
        {
          path: "/home/quiz/:ID",
          element: <QuizNew />,
        },
        //!Homework
        {
          path: "/home/homework/:ID",
          element: <QuizNew homework />,
        },
        //! Student Question Bank
        {
          path: "/home/question-bank",
          element: <StudentQuestionBank />,
        },
        {
          path: "/home/question-bank/:ID",
          element: <StudentShowQuestionBank />,
        },

        //! STUDENT MATRIALS
        //! est
        {
          path: "/home/est-materials",
          element: <StudentMaterialsStarter section="est" />,
        },
        {
          path: "/home/est-materials/past-paper",
          element: (
            <StudentMaterials section="est" pdfs pdfsType="past-paper" />
          ),
        },
        {
          path: "/home/est-materials/matrials",
          element: <StudentMaterialsView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading",
          element: <StudentMaterialsViewReadingOrWriting section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing",
          element: <StudentMaterialsViewReadingOrWriting section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/videos",
          element: <StudentMaterials section="est" reading videos />,
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials section="est" reading pdfs pdfsType="tests" />
          ),
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials section="est" reading pdfs pdfsType="books" />
          ),
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials section="est" reading pdfs pdfsType="topics" />
          ),
        },

        {
          path: "/home/est-materials/matrials/writing/videos",
          element: <StudentMaterials section="est" videos />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs/tests",
          element: <StudentMaterials section="est" pdfs pdfsType="tests" />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs/books",
          element: <StudentMaterials section="est" pdfs pdfsType="books" />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs/topics",
          element: <StudentMaterials section="est" pdfs pdfsType="topics" />,
        },

        {
          path: "/home/est-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials section="est" reading practicesType="exams" />
          ),
        },
        {
          path: "/home/est-materials/matrials/writing/practices/exams",
          element: <StudentMaterials section="est" practicesType="exams" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials section="est" reading practicesType="topics" />
          ),
        },
        {
          path: "/home/est-materials/matrials/writing/practices/quizzes/topics",
          element: <StudentMaterials section="est" practicesType="topics" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials section="est" reading practicesType="drills" />
          ),
        },
        {
          path: "/home/est-materials/matrials/writing/practices/quizzes/drills",
          element: <StudentMaterials section="est" practicesType="drills" />,
        },

        //! -------------------------------------------
        //! act
        {
          path: "/home/act-materials",
          element: <StudentMaterialsStarter section="act" />,
        },
        {
          path: "/home/act-materials/past-paper",
          element: (
            <StudentMaterials section="act" pdfs pdfsType="past-paper" />
          ),
        },
        {
          path: "/home/act-materials/matrials",
          element: <StudentMaterialsView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading",
          element: <StudentMaterialsViewReadingOrWriting section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing",
          element: <StudentMaterialsViewReadingOrWriting section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/videos",
          element: <StudentMaterials section="act" reading videos />,
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials section="act" reading pdfs pdfsType="tests" />
          ),
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials section="act" reading pdfs pdfsType="books" />
          ),
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials section="act" reading pdfs pdfsType="topics" />
          ),
        },

        {
          path: "/home/act-materials/matrials/writing/videos",
          element: <StudentMaterials section="act" videos />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs/tests",
          element: <StudentMaterials section="act" pdfs pdfsType="tests" />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs/books",
          element: <StudentMaterials section="act" pdfs pdfsType="books" />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs/topics",
          element: <StudentMaterials section="act" pdfs pdfsType="topics" />,
        },

        {
          path: "/home/act-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials section="act" reading practicesType="exams" />
          ),
        },
        {
          path: "/home/act-materials/matrials/writing/practices/exams",
          element: <StudentMaterials section="act" practicesType="exams" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials section="act" reading practicesType="topics" />
          ),
        },
        {
          path: "/home/act-materials/matrials/writing/practices/quizzes/topics",
          element: <StudentMaterials section="act" practicesType="topics" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials section="act" reading practicesType="drills" />
          ),
        },
        {
          path: "/home/act-materials/matrials/writing/practices/quizzes/drills",
          element: <StudentMaterials section="act" practicesType="drills" />,
        },
        //! Dsat
        {
          path: "/home/dsat-materials",
          element: <StudentMaterialsStarter section="dsat" />,
        },
        {
          path: "/home/dsat-materials/past-paper",
          element: (
            <StudentMaterials section="dsat" pdfs pdfsType="past-paper" />
          ),
        },
        {
          path: "/home/dsat-materials/matrials",
          element: <StudentMaterialsView section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/reading",
          element: <StudentMaterialsViewReadingOrWriting section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/writing",
          element: <StudentMaterialsViewReadingOrWriting section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/reading/videos",
          element: <StudentMaterials section="dsat" reading videos />,
        },
        {
          path: "/home/dsat-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials section="dsat" reading pdfs pdfsType="tests" />
          ),
        },
        {
          path: "/home/dsat-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials section="dsat" reading pdfs pdfsType="books" />
          ),
        },
        {
          path: "/home/dsat-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials section="dsat" reading pdfs pdfsType="topics" />
          ),
        },

        {
          path: "/home/dsat-materials/matrials/writing/videos",
          element: <StudentMaterials section="dsat" videos />,
        },
        {
          path: "/home/dsat-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/writing/pdfs/tests",
          element: <StudentMaterials section="dsat" pdfs pdfsType="tests" />,
        },
        {
          path: "/home/dsat-materials/matrials/writing/pdfs/books",
          element: <StudentMaterials section="dsat" pdfs pdfsType="books" />,
        },
        {
          path: "/home/dsat-materials/matrials/writing/pdfs/topics",
          element: <StudentMaterials section="dsat" pdfs pdfsType="topics" />,
        },

        {
          path: "/home/dsat-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials section="dsat" reading practicesType="exams" />
          ),
        },
        {
          path: "/home/dsat-materials/matrials/writing/practices/exams",
          element: <StudentMaterials section="dsat" practicesType="exams" />,
        },
        {
          path: "/home/dsat-materials/matrials/reading/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/writing/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="dsat" />,
        },
        {
          path: "/home/dsat-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials section="dsat" reading practicesType="topics" />
          ),
        },
        {
          path: "/home/dsat-materials/matrials/writing/practices/quizzes/topics",
          element: <StudentMaterials section="dsat" practicesType="topics" />,
        },
        {
          path: "/home/dsat-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials section="dsat" reading practicesType="drills" />
          ),
        },
        {
          path: "/home/dsat-materials/matrials/writing/practices/quizzes/drills",
          element: <StudentMaterials section="est" practicesType="drills" />,
        },

        {
          path: "/home/homeschooling",
          element: <HomePage />,
        },
        {
          path: "/home/est-materials/practise/:ID",
          element: <SingleExamPage />,
        },
        {
          path: "/home/act-materials/practise/:ID",
          element: <SingleExamPage />,
        },
        {
          path: "/home/dsat-materials/practise/:ID",
          element: <SingleExamPage />,
        },
        {
          path: "/home/homeschooling/practise/:ID",
          element: <SingleExamPage />,
        },
      ],
    },
    //!Admin Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/not-found"
          isAllowed={
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
          }
        >
          <AdminLayout />
        </ProtectedAdminRoutes>
      ),
      children: [
        //!------ students Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("students")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students",
              element: <Students />,
            },
            {
              path: "/admin/students/edit-student/:studentID",
              element: <EditStudent />,
            },
            {
              path: "/admin/students/view-student/:studentID",
              element: <ViewStudent />,
            },
          ],
        },

        //! courses
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("courses")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses",
              element: <AdminCourses />,
            },
            {
              path: "/admin/courses/:courseID",
              element: <AdminCourseDetails />,
            },
            {
              path: "/admin/courses/add-course",
              element: <AdminAddEditCourse />,
            },
            {
              path: "/admin/courses/edit-course/:courseID",
              element: <AdminAddEditCourse edit />,
            },
            {
              path: "/admin/courses/all-students/:courseID",
              element: <AdminViewExam addStudent />,
            },
            {
              path: "/admin/courses/all-students/:courseID/add-student",
              element: <AdminAddStudentToCourse />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID",
              element: <AdminCourseSession />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/add-video",
              element: <AdminAddEditMatrialSession type="videos" />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/add-pdf",
              element: <AdminAddEditMatrialSession type="pdf" />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/add-link",
              element: <AdminAddEditMatrialSession type="link" />,
            },

            {
              path: "/admin/courses/:courseID/session/:sessionID/add-exam",
              element: <AdminAddEditExamQuiz isExam />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/add-quiz",
              element: <AdminAddEditExamQuiz />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/exam/:examID",
              element: <AdminQuiz exam />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/quiz/:examID",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/exam/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/quiz/:examID/:sectionNumber",
              element: <AdminQuizSection />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/:examID",
              element: <AdminViewExam />,
            },

            {
              path: "/admin/courses/:courseID/session/:sessionID/edit-video",
              element: <AdminAddEditMatrialSession type="video" edit />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/edit-pdf",
              element: <AdminAddEditMatrialSession type="pdf" edit />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/edit-link",
              element: <AdminAddEditMatrialSession type="link" edit />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/edit-exam",
              element: <AdminAddEditExamQuiz isExam edit />,
            },
            {
              path: "/admin/courses/:courseID/session/:sessionID/edit-quiz",
              element: <AdminAddEditExamQuiz edit />,
            },

            {
              path: "/admin/courses/:courseID/add-session",
              element: <AdminAddEditSession />,
            },
            {
              path: "/admin/courses/:courseID/edit-session/:sessionID",
              element: <AdminAddEditSession edit />,
            },
          ],
        },

        // EST MATRIALS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("est")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/est-materials",
              element: <AdminMaterialsStarter type="est" />,
            },
            {
              path: "/admin/est-materials/past-paper",
              element: <AdminMaterialsPdf type="est" past />,
            },
            {
              path: "/admin/est-materials/past-paper/add-pdf",
              element: (
                <AdminAddEditMaterial section="est" type="pdf" reading past />
              ),
            },
            {
              path: "/admin/est-materials/past-paper/edit-pdf/:matrialID",
              element: (
                <AdminAddEditMaterial
                  edit
                  section="est"
                  type="pdf"
                  reading
                  past
                />
              ),
            },
            {
              path: "/admin/est-materials/materials",
              element: <AdminMatrialsType type="est" />,
            },
            {
              path: "/admin/est-materials/materials/reading",
              element: <AdminViewMatrials type="est" reading />,
            },
            {
              path: "/admin/est-materials/materials/writing",
              element: <AdminViewMatrials type="est" />,
            },
            // videos
            {
              path: "/admin/est-materials/materials/reading/:matrialType",
              element: <AdminMaterials type="est" reading />,
            },
            {
              path: "/admin/est-materials/materials/writing/:matrialType",
              element: <AdminMaterials type="est" />,
            },
            // add videos
            {
              path: "/admin/est-materials/materials/writing/add-video",
              element: <AdminAddEditMaterial section="est" type="video" />,
            },
            {
              path: "/admin/est-materials/materials/reading/add-video",
              element: (
                <AdminAddEditMaterial section="est" type="video" reading />
              ),
            },
            // edit vidoes
            {
              path: "/admin/est-materials/materials/reading/edit-videos/:matrialID",
              element: (
                <AdminAddEditMaterial section="est" type="video" edit reading />
              ),
            },
            {
              path: "/admin/est-materials/materials/writing/edit-videos/:matrialID",
              element: <AdminAddEditMaterial section="est" type="video" edit />,
            },
            // pdfs
            {
              path: "/admin/est-materials/materials/reading/pdfs/tests",
              element: <AdminMaterialsPdf type="est" reading tests />,
            },
            {
              path: "/admin/est-materials/materials/reading/pdfs/books",
              element: <AdminMaterialsPdf type="est" reading books />,
            },
            {
              path: "/admin/est-materials/materials/reading/pdfs/topics",
              element: <AdminMaterialsPdf type="est" reading topics />,
            },

            {
              path: "/admin/est-materials/materials/writing/pdfs/tests",
              element: <AdminMaterialsPdf type="est" tests />,
            },
            {
              path: "/admin/est-materials/materials/writing/pdfs/books",
              element: <AdminMaterialsPdf type="est" books />,
            },
            {
              path: "/admin/est-materials/materials/writing/pdfs/topics",
              element: <AdminMaterialsPdf type="est" topics />,
            },
            // add pdf
            {
              path: "/admin/est-materials/materials/reading/pdfs/tests/add-pdf",
              element: (
                <AdminAddEditMaterial section="est" type="pdf" reading tests />
              ),
            },
            {
              path: "/admin/est-materials/materials/reading/pdfs/books/add-pdf",
              element: (
                <AdminAddEditMaterial section="est" reading type="pdf" books />
              ),
            },
            {
              path: "/admin/est-materials/materials/reading/pdfs/topics/add-pdf",
              element: (
                <AdminAddEditMaterial section="est" reading type="pdf" topics />
              ),
            },
            {
              path: "/admin/est-materials/materials/writing/pdfs/tests/add-pdf",
              element: <AdminAddEditMaterial section="est" type="pdf" tests />,
            },
            {
              path: "/admin/est-materials/materials/writing/pdfs/books/add-pdf",
              element: <AdminAddEditMaterial section="est" type="pdf" books />,
            },
            {
              path: "/admin/est-materials/materials/writing/pdfs/topics/add-pdf",
              element: <AdminAddEditMaterial section="est" type="pdf" topics />,
            },
            // edit pdf
            {
              path: "/admin/est-materials/materials/reading/edit-pdfs/:matrialID",
              element: <AdminAddEditMaterial section="est" type="pdf" edit />,
            },
            {
              path: "/admin/est-materials/materials/writing/edit-pdfs/:matrialID",
              element: <AdminAddEditMaterial section="est" type="pdf" edit />,
            },
            // practise
            {
              path: "/admin/est-materials/materials/reading/practices",
              element: <AdminMatrialPractisesView section="est" reading />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices",
              element: <AdminMatrialPractisesView section="est" />,
            },
            {
              path: "/admin/est-materials/materials/reading/practices/exams/add-exam",
              element: (
                <AdminAddEditMatrialExamAndQuiz section="est" isExam reading />
              ),
            },
            {
              path: "/admin/est-materials/materials/writing/practices/exams/add-exam",
              element: <AdminAddEditMatrialExamAndQuiz section="est" isExam />,
            },
            {
              path: "/admin/est-materials/practices/edit-exam/:ID",
              element: (
                <AdminAddEditMatrialExamAndQuiz edit section="est" isExam />
              ),
            },
            {
              path: "/admin/est-materials/practices/edit-quiz/:ID",
              element: <AdminAddEditMatrialExamAndQuiz edit section="est" />,
            },
            // practise exams
            {
              path: "/admin/est-materials/materials/reading/practices/exams",
              element: <AdminMatrialPractises section="est" exam reading />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/exams",
              element: <AdminMatrialPractises section="est" exam />,
            },
            {
              path: "/admin/est-materials/materials/reading/practices/exams/:examID",
              element: <AdminQuiz exam />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/exams/:examID",
              element: <AdminQuiz exam />,
            },
            {
              path: "/admin/est-materials/materials/reading/practices/exams/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/exams/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            // practise quizzes
            {
              path: "/admin/est-materials/materials/reading/practices/quizzes",
              element: (
                <AdminMatrialPractisesQuizzesView section="est" reading />
              ),
            },
            {
              path: "/admin/est-materials/materials/writing/practices/quizzes",
              element: <AdminMatrialPractisesQuizzesView section="est" />,
            },

            // practise quizzes topics
            {
              path: "/admin/est-materials/materials/reading/practices/quizzes/topics",
              element: <AdminMatrialPractises section="est" reading topics />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/quizzes/topics",
              element: <AdminMatrialPractises section="est" topics />,
            },
            {
              path: "/admin/est-materials/materials/reading/practices/quizzes/topics/:examID",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/quizzes/topics/:examID",
              element: <AdminQuiz />,
            },

            // practise add quizzes
            {
              path: "/admin/est-materials/materials/reading/practices/quizzes/topics/add-quiz",
              element: (
                <AdminAddEditMatrialExamAndQuiz section="est" reading topics />
              ),
            },
            {
              path: "/admin/est-materials/materials/reading/practices/quizzes/drills/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="est" reading />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/quizzes/topics/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="est" topics />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/quizzes/drills/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="est" />,
            },

            // practise quizzes drills
            {
              path: "/admin/est-materials/materials/reading/practices/quizzes/drills",
              element: <AdminMatrialPractises section="est" reading drills />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/quizzes/drills",
              element: <AdminMatrialPractises section="est" drills />,
            },
            {
              path: "/admin/est-materials/materials/reading/practices/quizzes/drills/:examID",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/est-materials/materials/writing/practices/quizzes/drills/:examID",
              element: <AdminQuiz />,
            },

            // all students
            {
              path: "/admin/est-materials/practices/all-students/:examID",
              element: <AdminViewExam />,
            },
          ],
        },

        // ACT MATERIALS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("act")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/act-materials",
              element: <AdminMaterialsStarter type="act" />,
            },
            {
              path: "/admin/act-materials/past-paper",
              element: <AdminMaterialsPdf type="act" past />,
            },
            {
              path: "/admin/act-materials/past-paper/add-pdf",
              element: (
                <AdminAddEditMaterial section="act" type="pdf" reading past />
              ),
            },
            {
              path: "/admin/act-materials/past-paper/edit-pdf/:matrialID",
              element: (
                <AdminAddEditMaterial
                  edit
                  section="act"
                  type="pdf"
                  reading
                  past
                />
              ),
            },
            {
              path: "/admin/act-materials/materials",
              element: <AdminMatrialsType type="act" />,
            },
            {
              path: "/admin/act-materials/materials/reading",
              element: <AdminViewMatrials type="act" reading />,
            },
            {
              path: "/admin/act-materials/materials/writing",
              element: <AdminViewMatrials type="act" />,
            },
            // videos
            {
              path: "/admin/act-materials/materials/reading/:matrialType",
              element: <AdminMaterials type="act" reading />,
            },
            {
              path: "/admin/act-materials/materials/writing/:matrialType",
              element: <AdminMaterials type="act" />,
            },
            // add videos
            {
              path: "/admin/act-materials/materials/writing/add-video",
              element: <AdminAddEditMaterial section="act" type="video" />,
            },
            {
              path: "/admin/act-materials/materials/reading/add-video",
              element: (
                <AdminAddEditMaterial section="act" type="video" reading />
              ),
            },
            // edit vidoes
            {
              path: "/admin/act-materials/materials/reading/edit-videos/:matrialID",
              element: (
                <AdminAddEditMaterial section="act" type="video" edit reading />
              ),
            },
            {
              path: "/admin/act-materials/materials/writing/edit-videos/:matrialID",
              element: <AdminAddEditMaterial section="act" type="video" edit />,
            },
            // pdfs
            {
              path: "/admin/act-materials/materials/reading/pdfs/tests",
              element: <AdminMaterialsPdf type="act" reading tests />,
            },
            {
              path: "/admin/act-materials/materials/reading/pdfs/books",
              element: <AdminMaterialsPdf type="act" reading books />,
            },
            {
              path: "/admin/act-materials/materials/reading/pdfs/topics",
              element: <AdminMaterialsPdf type="act" reading topics />,
            },

            {
              path: "/admin/act-materials/materials/writing/pdfs/tests",
              element: <AdminMaterialsPdf type="act" tests />,
            },
            {
              path: "/admin/act-materials/materials/writing/pdfs/books",
              element: <AdminMaterialsPdf type="act" books />,
            },
            {
              path: "/admin/act-materials/materials/writing/pdfs/topics",
              element: <AdminMaterialsPdf type="act" topics />,
            },
            // add pdf
            {
              path: "/admin/act-materials/materials/reading/pdfs/tacts/add-pdf",
              element: (
                <AdminAddEditMaterial section="act" type="pdf" reading tests />
              ),
            },
            {
              path: "/admin/act-materials/materials/reading/pdfs/books/add-pdf",
              element: (
                <AdminAddEditMaterial section="act" reading type="pdf" books />
              ),
            },
            {
              path: "/admin/act-materials/materials/reading/pdfs/topics/add-pdf",
              element: (
                <AdminAddEditMaterial section="act" reading type="pdf" topics />
              ),
            },
            {
              path: "/admin/act-materials/materials/writing/pdfs/tests/add-pdf",
              element: <AdminAddEditMaterial section="act" type="pdf" tests />,
            },
            {
              path: "/admin/act-materials/materials/writing/pdfs/books/add-pdf",
              element: <AdminAddEditMaterial section="act" type="pdf" books />,
            },
            {
              path: "/admin/act-materials/materials/writing/pdfs/topics/add-pdf",
              element: <AdminAddEditMaterial section="act" type="pdf" topics />,
            },
            // edit pdf
            {
              path: "/admin/act-materials/materials/reading/edit-pdfs/:matrialID",
              element: <AdminAddEditMaterial section="act" type="pdf" edit />,
            },
            {
              path: "/admin/act-materials/materials/writing/edit-pdfs/:matrialID",
              element: <AdminAddEditMaterial section="act" type="pdf" edit />,
            },
            // practise
            {
              path: "/admin/act-materials/materials/reading/practices",
              element: <AdminMatrialPractisesView section="act" reading />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices",
              element: <AdminMatrialPractisesView section="act" />,
            },
            {
              path: "/admin/act-materials/materials/reading/practices/exams/add-exam",
              element: (
                <AdminAddEditMatrialExamAndQuiz section="act" isExam reading />
              ),
            },
            {
              path: "/admin/act-materials/materials/writing/practices/exams/add-exam",
              element: <AdminAddEditMatrialExamAndQuiz section="act" isExam />,
            },
            {
              path: "/admin/act-materials/practices/edit-exam/:ID",
              element: (
                <AdminAddEditMatrialExamAndQuiz edit section="act" isExam />
              ),
            },
            {
              path: "/admin/act-materials/practices/edit-quiz/:ID",
              element: <AdminAddEditMatrialExamAndQuiz edit section="act" />,
            },
            // practise exams
            {
              path: "/admin/act-materials/materials/reading/practices/exams",
              element: <AdminMatrialPractises section="act" exam reading />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/exams",
              element: <AdminMatrialPractises section="act" exam />,
            },
            {
              path: "/admin/act-materials/materials/reading/practices/exams/:examID",
              element: <AdminQuiz exam />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/exams/:examID",
              element: <AdminQuiz exam />,
            },
            {
              path: "/admin/act-materials/materials/reading/practices/exams/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/exams/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            // practise quizzes
            {
              path: "/admin/act-materials/materials/reading/practices/quizzes",
              element: (
                <AdminMatrialPractisesQuizzesView section="act" reading />
              ),
            },
            {
              path: "/admin/act-materials/materials/writing/practices/quizzes",
              element: <AdminMatrialPractisesQuizzesView section="act" />,
            },

            // practise quizzes topics
            {
              path: "/admin/act-materials/materials/reading/practices/quizzes/topics",
              element: <AdminMatrialPractises section="act" reading topics />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/quizzes/topics",
              element: <AdminMatrialPractises section="act" topics />,
            },
            {
              path: "/admin/act-materials/materials/reading/practices/quizzes/topics/:examID",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/quizzes/topics/:examID",
              element: <AdminQuiz />,
            },

            // practise add quizzes
            {
              path: "/admin/act-materials/materials/reading/practices/quizzes/topics/add-quiz",
              element: (
                <AdminAddEditMatrialExamAndQuiz section="act" reading topics />
              ),
            },
            {
              path: "/admin/act-materials/materials/reading/practices/quizzes/drills/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="act" reading />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/quizzes/topics/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="act" topics />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/quizzes/drills/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="act" />,
            },

            // practise quizzes drills
            {
              path: "/admin/act-materials/materials/reading/practices/quizzes/drills",
              element: <AdminMatrialPractises section="act" reading drills />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/quizzes/drills",
              element: <AdminMatrialPractises section="act" drills />,
            },
            {
              path: "/admin/act-materials/materials/reading/practices/quizzes/drills/:examID",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/act-materials/materials/writing/practices/quizzes/drills/:examID",
              element: <AdminQuiz />,
            },

            // all students
            {
              path: "/admin/act-materials/practices/all-students/:examID",
              element: <AdminViewExam />,
            },
          ],
        },
        // DSAT MATRIALS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("dsat")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/dsat-materials",
              element: <AdminMaterialsStarter type="dsat" />,
            },
            {
              path: "/admin/dsat-materials/past-paper",
              element: <AdminMaterialsPdf type="dsat" past />,
            },
            {
              path: "/admin/dsat-materials/past-paper/add-pdf",
              element: (
                <AdminAddEditMaterial section="dsat" type="pdf" reading past />
              ),
            },
            {
              path: "/admin/dsat-materials/past-paper/edit-pdf/:matrialID",
              element: (
                <AdminAddEditMaterial
                  edit
                  section="dsat"
                  type="pdf"
                  reading
                  past
                />
              ),
            },
            {
              path: "/admin/dsat-materials/materials",
              element: <AdminMatrialsType type="dsat" />,
            },
            {
              path: "/admin/dsat-materials/materials/reading",
              element: <AdminViewMatrials type="dsat" reading />,
            },
            {
              path: "/admin/dsat-materials/materials/writing",
              element: <AdminViewMatrials type="dsat" />,
            },
            // videos
            {
              path: "/admin/dsat-materials/materials/reading/:matrialType",
              element: <AdminMaterials type="dsat" reading />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/:matrialType",
              element: <AdminMaterials type="dsat" />,
            },
            // add videos
            {
              path: "/admin/dsat-materials/materials/writing/add-video",
              element: <AdminAddEditMaterial section="dsat" type="video" />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/add-video",
              element: (
                <AdminAddEditMaterial section="dsat" type="video" reading />
              ),
            },
            // edit vidoes
            {
              path: "/admin/dsat-materials/materials/reading/edit-videos/:matrialID",
              element: (
                <AdminAddEditMaterial
                  section="dsat"
                  type="video"
                  edit
                  reading
                />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/writing/edit-videos/:matrialID",
              element: (
                <AdminAddEditMaterial section="dsat" type="video" edit />
              ),
            },
            // pdfs
            {
              path: "/admin/dsat-materials/materials/reading/pdfs/tests",
              element: <AdminMaterialsPdf type="dsat" reading tests />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/pdfs/books",
              element: <AdminMaterialsPdf type="dsat" reading books />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/pdfs/topics",
              element: <AdminMaterialsPdf type="dsat" reading topics />,
            },

            {
              path: "/admin/dsat-materials/materials/writing/pdfs/tests",
              element: <AdminMaterialsPdf type="dsat" tests />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/pdfs/books",
              element: <AdminMaterialsPdf type="dsat" books />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/pdfs/topics",
              element: <AdminMaterialsPdf type="dsat" topics />,
            },
            // add pdf
            {
              path: "/admin/dsat-materials/materials/reading/pdfs/tests/add-pdf",
              element: (
                <AdminAddEditMaterial section="dsat" type="pdf" reading tests />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/reading/pdfs/books/add-pdf",
              element: (
                <AdminAddEditMaterial section="dsat" reading type="pdf" books />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/reading/pdfs/topics/add-pdf",
              element: (
                <AdminAddEditMaterial
                  section="dsat"
                  reading
                  type="pdf"
                  topics
                />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/writing/pdfs/tests/add-pdf",
              element: <AdminAddEditMaterial section="dsat" type="pdf" tests />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/pdfs/books/add-pdf",
              element: <AdminAddEditMaterial section="dsat" type="pdf" books />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/pdfs/topics/add-pdf",
              element: (
                <AdminAddEditMaterial section="dsat" type="pdf" topics />
              ),
            },
            // edit pdf
            {
              path: "/admin/dsat-materials/materials/reading/edit-pdfs/:matrialID",
              element: <AdminAddEditMaterial section="dsat" type="pdf" edit />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/edit-pdfs/:matrialID",
              element: <AdminAddEditMaterial section="dsat" type="pdf" edit />,
            },
            // practise
            {
              path: "/admin/dsat-materials/materials/reading/practices",
              element: <AdminMatrialPractisesView section="dsat" reading />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices",
              element: <AdminMatrialPractisesView section="dsat" />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/practices/exams/add-exam",
              element: (
                <AdminAddEditMatrialExamAndQuiz section="dsat" isExam reading />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/exams/add-exam",
              element: <AdminAddEditMatrialExamAndQuiz section="dsat" isExam />,
            },
            {
              path: "/admin/dsat-materials/practices/edit-exam/:ID",
              element: (
                <AdminAddEditMatrialExamAndQuiz edit section="dsat" isExam />
              ),
            },
            {
              path: "/admin/dsat-materials/practices/edit-quiz/:ID",
              element: <AdminAddEditMatrialExamAndQuiz edit section="dsat" />,
            },
            // practise exams
            {
              path: "/admin/dsat-materials/materials/reading/practices/exams",
              element: <AdminMatrialPractises section="dsat" exam reading />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/exams",
              element: <AdminMatrialPractises section="dsat" exam />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/practices/exams/:examID",
              element: <AdminQuiz exam />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/exams/:examID",
              element: <AdminQuiz exam />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/practices/exams/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/exams/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            // practise quizzes
            {
              path: "/admin/dsat-materials/materials/reading/practices/quizzes",
              element: (
                <AdminMatrialPractisesQuizzesView section="dsat" reading />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/quizzes",
              element: <AdminMatrialPractisesQuizzesView section="dsat" />,
            },

            {
              path: "/admin/dsat-materials/materials/writing/practices/exams/add-exam",
              element: <AdminAddEditMatrialExamAndQuiz section="dsat" isExam />,
            },
            // practise quizzes topics
            {
              path: "/admin/dsat-materials/materials/reading/practices/quizzes/topics",
              element: <AdminMatrialPractises section="dsat" reading topics />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/quizzes/topics",
              element: <AdminMatrialPractises section="dsat" topics />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/practices/quizzes/topics/:examID",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/quizzes/topics/:examID",
              element: <AdminQuiz />,
            },

            // practise add quizzes
            {
              path: "/admin/dsat-materials/materials/reading/practices/quizzes/topics/add-quiz",
              element: (
                <AdminAddEditMatrialExamAndQuiz section="dsat" reading topics />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/reading/practices/quizzes/drills/add-quiz",
              element: (
                <AdminAddEditMatrialExamAndQuiz section="dsat" reading />
              ),
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/quizzes/topics/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="dsat" topics />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/quizzes/drills/add-quiz",
              element: <AdminAddEditMatrialExamAndQuiz section="dsat" />,
            },

            // practise quizzes drills
            {
              path: "/admin/dsat-materials/materials/reading/practices/quizzes/drills",
              element: <AdminMatrialPractises section="dsat" reading drills />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/quizzes/drills",
              element: <AdminMatrialPractises section="dsat" drills />,
            },
            {
              path: "/admin/dsat-materials/materials/reading/practices/quizzes/drills/:examID",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/dsat-materials/materials/writing/practices/quizzes/drills/:examID",
              element: <AdminQuiz />,
            },

            // all students
            {
              path: "/admin/dsat-materials/practices/all-students/:examID",
              element: <AdminViewExam />,
            },
          ],
        },
        // HOMESCHOOLING
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("homeschooling")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/homeschooling",
              element: <Posts />,
            },
            {
              path: "/admin/homeschooling/:postID",
              element: <AddEditPost edit />,
            },
          ],
        },

        // EDIT QUESTIONS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },

        //! exams

        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("exam_view")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/exams/all-students/:examID",
        //       element: <AdminViewExam isExam />,
        //     },
        //     {
        //       path: "/admin/exams/all-students/:examID/model-answer",
        //       element: <AdminModalAnswer isExam />,
        //     },
        //   ],
        // },

        {
          element: (
            <ProtectedAdminRoutes redirectPath="/not-found" isAllowed={true} />
          ),
          children: [
            {
              path: "/admin/profile",
              element: <AdminProfile />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/assistants",
              element: <Assistants />,
            },
            {
              path: "/admin/assistants/add-assistant",
              element: <AddEditAssistant />,
            },
            {
              path: "/admin/assistants/edit-assistant/:assistantID",
              element: <AddEditAssistant edit />,
            },
          ],
        },
      ],
    },
    //!SignIn-routes
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/students"
              : "/home/courses"
          }`}
          isAllowed={!AuthState.userData}
        />
      ),
      children: [
        //!SignIn

        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/admin/signin",
          element: <SignIn admin />,
        },
        //!Register
        {
          path: "/register",
          element: <Register />,
        },
      ],
    },

    //!NotFound
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return (
    <main className="dar:text-light bg-white text-secondary duration-100 dark:bg-dark">
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </main>
  );
}

export default App;
