import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const QuizTimer = ({ state, duration, onFinishQuiz }) => {
  const { ID } = useParams();

  const initialDuration =
    localStorage.getItem(`Quiz${ID}${state?.quizInfo.title}`) <= 0
      ? duration * 60
      : localStorage.getItem(`Quiz${ID}${state?.quizInfo.title}`);
  const [timerDuration, setTimerDuration] = useState(initialDuration);
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerDuration((prevDuration) => prevDuration - 1);
    }, 1000);

    if (timerDuration <= 1) {
      onFinishQuiz();
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timerDuration]);

  // set time duration in localstorage
  useEffect(() => {
    localStorage.setItem(`Quiz${ID}${state?.quizInfo.title}`, timerDuration);

    localStorage.setItem(`Quiz${ID}${state?.quizInfo.title}FromAPI`, duration);
  }, [ID, duration, state?.quizInfo.title, timerDuration]);

  useEffect(() => {
    if (timerDuration === 0) {
      // console.log("Timer ended!"); // Replace this with your desired code or action
    } else {
      const minutes = Math.floor(timerDuration / 60);
      const seconds = timerDuration % 60;
      const formatted = `${minutes.toString().padStart(2, "0")} min : ${seconds
        .toString()
        .padStart(2, "0")} sec`;
      setFormattedTime(formatted);
    }
  }, [timerDuration]);

  return (
    <div className="timer-wrapper flex  items-center  md:w-fit">
      <div className="timer font-bold">{formattedTime}</div>
    </div>
  );
};

export default QuizTimer;
